import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CTA from "../../components/cta"
import USP from "../../components/usp"
import HeroSectionAlt from "../../components/heroSectionAlt"
import Testimonials from "../../components/testimonials"

function FaucetRepair() {
  const data = useStaticQuery(graphql`
    query cypressImages {
      cypressPlumber: file(relativePath: { eq: "cypress-plumber.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Cypress Plumbing Company"
        description={`Our company has been providing plumbing services for the Cypress community for over a decade. We offer all kinds of residential plumbing repairs. `}
      />
      <HeroSectionAlt
        h1={`Cypress Plumbing`}
        h2={`Your Local Cypress Master Plumber`}
        h3={`Have a plumbing problem? Our highly-skilled, professional, and prompt company has served Cypress residents since 2006. Whether it’s a clog, leak, busted pipe, or water heater issue, we’ve got your back so you can have your home up and running in no time.`}
        p1={`Talk directly with a master plumber today to get the right (and 100% free) advice for your particular need.`}
        heroImg={data.cypressPlumber.childImageSharp.fluid}
        heroImgAltTag={`Cypress Plumbing Company`}
      />
      <section>
        <div className="pb-16 px-2 md:px-0">
          <h2 className="font-bold leading-8 text-2xl mb-4">
            About Klein Plumbing Services
          </h2>
          <div className="flex flex-col-reverse lg:flex-row">
            <div className="md:w-full lg:mr-8">
              <div className="md:flex">
                <p className="text-gray-600 leading-normal text-lg mb-4"></p>
              </div>
              <div>
                <p className="text-gray-600 text-lg mb-4">
                  Klein Plumbing Service’s transparent and straightforward
                  plumbing solutions have earned us a sterling reputation with
                  the Cypress community. Our affordable pricing, honest advice,
                  and expert services have allowed us to provide solutions for
                  countless members in Cypress.
                </p>
                <p className="text-gray-600 text-lg mb-4">
                  All members of our team are licensed, insured, and incredibly
                  capable of getting your home functioning like it’s supposed
                  to. We understand your schedule is busy - and a plumbing
                  repair is the last thing you want to worry about. That’s why
                  we accommodate your needs in a time frame that works for you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <USP />
      {/* <Testimonials mainTitle={`Our Customers`} /> */}
      <CTA
        title={`Get a Free Quote Now`}
        buttonTitle={`Talk With a Master Plumber`}
      />
    </Layout>
  )
}

export default FaucetRepair
